<template>
  <div class="cloudinary">
    <h1>{{ msg }}</h1>
    <div id="dropbox">
    <h1></h1>
    <!--v-model="image"
    upload-preset="yffhaggf"-->
    <vuetifyCloudinaryUpload 
        buttonColor="#0093EE"
        :buttonDark=true
        buttonIcon='<i class="material-icons">cloud_upload</i>'
        buttonText="add audio"
        upload-preset="414e034dcfb5468e9860ca8e037a16fa"
        cloud-name="songxy"
    />
    <form class="my-form">
        <div class="form_line">
        <h4></h4>
        <div class="form_controls">
            <div class="upload_button_holder">
            <input type="file" id="fileElem" multiple accept="image/*" style="display:none" onchange="handleFiles(this.files)">
            <!--<a href="#" id="fileSelect">Select some files</a>
            <a href="#" id="urlSelect">URL Upload</a>-->
            </div>
        </div>
        </div>
    </form>
    <div class="progress-bar" id="progress-bar">
        <div class="progress" id="progress"></div>
    </div>
    <div id="gallery" />
    </div>
  </div>
</template>

<script>

import vuetifyCloudinaryUpload from '@/components/CloudinaryUploader'
//import vuetifyCloudinaryUpload from 'vuetify-cloudinary-upload'
export default {
    components: { vuetifyCloudinaryUpload },
    name: 'Cloudinary',
    props: {
        msg: String
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

<style>
[class^="gb-"]:not(.gb-base-icon) {
  font-family: Arial, Helvetica, sans-serif !important;
}
</style>