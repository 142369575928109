import {db} from '@/config/firebase'



// date issue fix according to firebase
//const settings = {
//  timestampsInSnapshots: true
//}

//db.settings(settings)

const dbs = {
  users: db.collection('users'),
  //todos: db.collection('todos')
}

export default dbs

//export const db = firebase.firestore()