<template>
  <div id="app">
    <v-app id="inspire">
      <!--<v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app temporary>-->
      <!--<div id="nav">
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
      </div>-->
      <!--</v-navigation-drawer>-->
      <v-layout>
        <!--<v-flex xs12 sm6 offset-sm3>-->
        <!--<v-flex xs12 sm12>-->
        <v-flex xs12 sm8 offset-sm2>
          <v-card>
            <!--<v-img src="https://source.unsplash.com/1024x1024/?audio,music,musician,mastering studio" aspect-ratio="2.75">-->
              <span class="my-span">
                <router-view/>
              </span>
            <!--</v-img>-->
          </v-card>
        </v-flex>
      </v-layout>
    </v-app>
  </div>
</template>

<script>
  export default {
    data: () => ({
      dark: null,
      drawer: null,
      items: [
        {
          key: 'main',
          action: 'mdi-audiobook ',
          title: 'vuetify-audio',
          active: false,
          items: [
            { title: 'Demo', url: '#/vuetifyaudio' },
            { title: 'Github', url: 'https://github.com/wilsonwu/vuetify-audio' }
          ]
        },
        {
          key: 'setting',
          action: 'mdi-settings',
          title: 'About Wilson',
          active: false,
          items: [
            { title: 'Wilson Profile', url: 'http://wilsonwu.me' }
          ]
        }
      ],
    }),
    created () {
      this.dark = this.$vuetify.theme.dark
    },
    methods: {
      setTheme () {
        this.$vuetify.theme.dark = !this.dark   
        this.dark = !this.dark
      }
    },
  }
</script>


<style lang="scss">

.my-span {
  background-color: blue;
  color: white;
  font-weight: bold;
  margin-right: 0;
  float: center;
}
/*body {
  height: 100vh;
  margin: 0;
  padding: 0px;
  width: 100%;
}*/
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #FFFFFF;
  color: #2c3e50;
  background-color: #222C3C;*/
  /*display: flex;*/
  height: 100%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
