<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <DragAndDropUploader msg="Songxy Audio Mastering"/>
    <Cloudinary msg = "" />
    <SignUp/>
    <Login/>
    <AccountInfo/>
  </div>
</template>

<script>
// @ is an alias to /src
import DragAndDropUploader from '@/components/DragAndDropUploader.vue'
import Cloudinary from '@/components/Cloudinary.vue'
import SignUp from '@/components/SignUp.vue'
import Login from '@/components/Login.vue'
import AccountInfo from '@/components/AccountInfo.vue'

export default {
  name: 'home',
  components: {
    DragAndDropUploader,
    Cloudinary,
    SignUp,
    Login,
    AccountInfo
  },
  data () {
    return {
      
    }
  },
  computed: {
    
  },
  methods: {
    
  },
  mounted() {
    
  }
}
</script>
