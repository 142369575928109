<template>
  <div>
  </div>
</template>

<script>
import {fb, auth} from '@/config/firebase'
import dbs from '@/config/firestore' //this is a dict abstraction for db firestore
import { mapMutations } from 'vuex'
import store from '../store/index'
import { mapState } from 'vuex'

//this is the right way to do it according to firebase function docs here:
//https://github.com/firebase/functions-samples/tree/master/quickstarts/time-server
//const urlIp1 = 'https://us-central1-songxy-867de.cloudfunctions.net/getIp_877edd46de944394a67e6fe8f3807047' //'https://api.ipdata.co/?api-key='+api_key //'https://songxy.com/cdn-cgi/trace'//'https://ipinfo.io/json'
//const urlIpFree = 'http://ip-api.com/json'
//const urlIpFree = 'https://api.ipregistry.co/?key=tryout'
const urlIp = 'https://songxy.com/cdn-cgi/trace'
//const blacklist = ['KP']
//we can look up the ip address later since we have them from cdn-cgi/trace

export default {
  name: 'Login',
  async beforeMount(){
    try {
      const response = await this.$http.get(urlIp)
      this.results.push(response.data)
      this.signInAnon()
    }catch(err) {
      console.log(err)
      this.signInAnon()  
    }
    /*this.$http.get(urlIp)
    .then((response2) => {  
      this.results.push(response2.data)
      this.signInAnon()
    })
    .catch( err => {
      console.log(err+' bad')
      this.signInAnon()  
    })*/
  /*
  this.$http.get(urlIpCloudflare)
  .then((response2) => {  
    this.results.push(response2.data)
    this.$http.get(urlIpFree)
    .then((response) => {
      console.log(response)
      this.results.push(response.data)
      if (blacklist.includes(response.data.location.country.code)) {
        alert('Sorry, this content is not available at your location.');
      } else {
        this.signInAnon()
      }   
    })
    .catch( err => {
      console.log(err)
      this.signInAnon()   
    })  
  })
  .catch( err => {
    console.log(err+' bad')
    this.signInAnon()  
  })*/
    /*this.$http.get(urlIp1).then((response) => {
      this.$http.get(response.data)
      .then((response1) => {
        this.results.push(response1.data)
        this.$http.get(urlIp2)
        .then((response2) => {  
          this.results.push(response2.data)
          if (blacklist.includes(response1.data.country_code)) {
            alert('Sorry, this content is not available at your location.');
          } else {
            this.signInAnon()
          }   
        })
        .catch( err => {
          console.log(err)
          if (blacklist.includes(response1.data.country_code)) {
            alert('Sorry, this content is not available at your location.');
          } else {
            this.signInAnon()
          }   
        })
      })
      .catch( err => {
        console.log(err+' bad1')
        this.signInAnon()  
      })
    })
    .catch( err => {
      console.log(err+' bad2')
      this.signInAnon()
    })*/

  },
  mounted() {
    
    this.$validator.localize('en', this.dictionary)
  },
  data() {
    return {
      userId: '',
      results: [],
      submitting: false,
      dictionary: {
        attributes: {
          email: 'e-mail address'
        }
      },
      form: {
        email: '',
        password: '',
        remember: null
      }
    }
  },
  computed: {
    ...mapState(['currentUser', 'userProfile']),
    isFormValid () {
      return !Object.keys(this.fields).some(key => this.fields[key].invalid)
    },
    //what is fb.auth.Auth it definitely seems wrong here!!!!
    firebasePersistence() {
      return this.form.remember
        ? fb.auth.Auth.Persistence.LOCAL
        : fb.auth.Auth.Persistence.SESSION
    }
  },
  methods: {
    ...mapMutations([
      'SHOW_SNACKBAR',
      'HIDE_SNACKBAR'
    ]),
    signInAnon() {
      console.log('++++++++++++++++++++called by beforeMount')
      //store.dispatch('FETCH_AUTH').then(currentUser => {
      //  console.log('****************** user login information here')
      //  console.log(currentUser)
      
      

      auth.onAuthStateChanged(user => {
        if(user)
        {
          console.log('************* user already logged in')
          console.log(user)

          //track realtime changes to userId document
          this.userId = user.uid
          
          //db.collection("users").doc(user.uid)
          dbs.users.doc(user.uid)
          .onSnapshot(function(doc) {
              console.log("Current data realtime updates: ", doc.data());
          })
          console.log('tracking realtime firestore updates for user')

          store.dispatch('FETCH_AUTH').then(currentUser => {
            console.log(currentUser)
            store.dispatch('ROUTE_COMPLETE')
          })
        }
        else{
          console.log('************* logging in as anonymous')
          //console.log(user)
          auth.signInAnonymously().then( response => {
            console.log('&&&&&&&&&&&&&&&&&&&&&&&the current user state here are the ip data results:')
            console.log(this.results)
            
            this.userId = response.user.uid

            const userData = {
              displayName: 'anonymous', //this.results[0].ip,
              uid: response.user.uid,
              anonUID: response.user.uid,
              wasAnonAccount: true,
              userData: this.results
            }
            //all updates and additional info must go through API to avoid firestore hijacking data overload
            //so we still need to get the ip address info and then parse it and take the ip v4 or v6 and pass to api call
            //and we can still update the username or displayname but through api call only
            //firestore is readonly
            //console.log( 'Anonymous Login', response )
            // Update user account displayName
            //response.user.updateProfile({ displayName: this.results[0].ip })
      //      response.user.updateProfile({ displayName: 'anonymous' })
            
            //console.log('************** Attempt to create firestore entry in users collection for anonymous user')
            
            // Create db entry in 'users' collection for this anonymous user
            dbs.users.doc( response.user.uid ).set( userData )
            console.log('************** created firestore entry in users collection for anonymous user')
            //this.$router.replace({
            //  name: 'Home'
            //})
            
            store.dispatch('FETCH_AUTH').then(currentUser => {
              console.log(currentUser)
              store.dispatch('ROUTE_COMPLETE')
            })

            
          }).catch( error => {
            this.submitting = false
            this.form.password = ''
            this.SHOW_SNACKBAR(error.message)
          })
          
        }
      })
      
        //let userId = state.currentUser && state.currentUser.uid ? state.currentUser.uid : false
        //dbs.users.doc( currentUser.uid ).set( currentUser )
        //store.dispatch('ROUTE_COMPLETE')
      //})
      /*
      
      })*/
      
      
    },
    submit() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.submitting = true
          this.HIDE_SNACKBAR()

          auth.setPersistence(this.firebasePersistence).then(() => {
            return auth.signInWithEmailAndPassword(this.form.email, this.form.password).then(response => {
              console.log(response)
              //if (this.$route.query.redirect) {
              //  this.$router.replace({
              //    path: this.$route.query.redirect
              //  })
              //} else {
              //  this.$router.replace({
              //    name: 'Home'
              //  })
             // }
            }).catch(error => {
              this.submitting = false
              this.form.password = ''
              this.fields.password.invalid = true
              this.SHOW_SNACKBAR(error.message)
            })
          }).catch(error => {
            this.submitting = false
            this.form.password = ''
            this.fields.password.invalid = true
            this.SHOW_SNACKBAR(error.message)
          })
        }
      })
    }
  }

  
}
</script>
