<template>
  <div class="dragAndDropUploader" v-cloak @drop.prevent="addFile" @dragover.prevent>
    <h1>{{ msg }}</h1>
    <!-- Latest update -->
    <h2>Files to Upload (Drag them over)</h2>
    <ul>
      <li v-for="file in files" :key="file.uuid">
        {{ file.name }} ({{ file.size | kb }} kb) <v-btn @click="removeFile(file)" title="Remove">X</v-btn>
      </li>
    </ul>
    <v-btn :disabled="uploadDisabled" @click="upload">Upload</v-btn>
    <div class="dropzone"></div>
  </div>
</template>

<script>
export default {
  name: 'DragAndDropUploader',
  data () {
    return {
      files:[]
    }
  },
  props: {
    msg: String
  },
  computed: {
    uploadDisabled() {
      return this.files.length === 0;
    }
  },
  methods:{
    addFile(e) {
      let droppedFiles = e.dataTransfer.files;
      if(!droppedFiles) return;
      // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      ([...droppedFiles]).forEach(f => {
        console.log(f)
        if(f['type']==='audio/aac' || f['type']==='audio/aiff'|| f['type']==='audio/m4a'|| 
          f['type']==='audio/mp3'|| f['type']==='audio/ogg' || f['type']==='audio/wav' || 
          f['type']==='audio/wave' || f['type']==='audio/x-pn-wav' || f['type']==='audio/x-wav' || 
          f['type']==='audio/vnd.wave')
          this.files.push(f);
      });
    },
    removeFile(file){
      this.files = this.files.filter(f => {
        return f != file;
      });      
    },
    upload() {
      
      let formData = new FormData();
      this.files.forEach((f,x) => {
        formData.append('file'+(x+1), f);
      });
      
      fetch('https://httpbin.org/post', {
        method:'POST',
        body: formData
      })
      .then(res => res.json())
      .then(res => {
         console.log('done uploading', res);
      })
      .catch(e => {
        console.error(JSON.stringify(e.message));
      });
      
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

<style scoped>
[class^="gb-"]:not(.gb-base-icon) {
  font-family: Arial, Helvetica, sans-serif !important;
}

.dragAndDropUploader {
  background-color: #171E29;
  border: 3px solid #313D4F;
  border-style: dashed;
}

.dropzone {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /*get rid of this -1 and activate dropzone when dragging a file to drop*/
}
.dropzone:hover {
    border: 3px solid red;
    border-style: dashed;
}

</style>