<template>
  <div>
    <v-container>
      <p v-if="! currentUser.isAnonymous">
        Welcome <strong>{{ currentUser.displayName }}</strong>!  You're using a full standard account!
      </p>
      <p v-else>
        You're signed in under an Anonymous Account!
      </p>
      <v-card v-if="userProfile">
        <v-card-title><h4>User Profile</h4></v-card-title>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-item v-if="typeof userProfile.wasAnonymous !== 'undefined'">
            <v-list-item-content>wasAnonymous</v-list-item-content>
            <v-list-item-content class="align-end">{{userProfile.wasAnonymous}}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>UID</v-list-item-content>
            <v-list-item-content class="align-end">{{userProfile.uid}}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>displayName</v-list-item-content>
            <v-list-item-content class="align-end">{{userProfile.displayName}}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>anonUID</v-list-item-content>
            <v-list-item-content class="align-end">{{userProfile.anonUID}}</v-list-item-content>
          </v-list-item>
          <v-list-item v-if="userProfile.randomAnonOnlyData">
            <v-list-item-content>Random Anon Only Data</v-list-item-content>
            <v-list-item-content class="align-end">{{userProfile.randomAnonOnlyData}}</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
      <v-card v-if="currentUser">
        <v-card-title><h4>Current User</h4></v-card-title>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>UID</v-list-item-content>
            <v-list-item-content class="align-end">{{currentUser.uid}}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>displayName</v-list-item-content>
            <v-list-item-content class="align-end">{{currentUser.displayName}}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>isAnonymous</v-list-item-content>
            <v-list-item-content class="align-end">{{currentUser.isAnonymous}}</v-list-item-content>
          </v-list-item>
          <v-list-item v-if="currentUser.metadata && currentUser.metadata.creationTime">
            <v-list-item-content>creationTime</v-list-item-content>
            <v-list-item-content class="align-end">{{currentUser.metadata.creationTime}}</v-list-item-content>
          </v-list-item>
          <v-list-item v-if="currentUser.metadata && currentUser.metadata.lastSignInTime">
            <v-list-item-content>lastSignInTime</v-list-item-content>
            <v-list-item-content class="align-end">{{currentUser.metadata.lastSignInTime}}</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>

    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AccountInfo',
  computed: mapState(['currentUser', 'userProfile'])
}
</script>
