import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
//import 'firebase/database'

const options = {
  apiKey: "AIzaSyBl1WX9R7rTC8gvQGCX4xD9HXFj5xlKTMA",
  //authDomain: "songxy-867de.firebaseapp.com",
  //databaseURL: "https://songxy-867de.firebaseio.com",
  projectId: "songxy-867de",
  //storageBucket: "songxy-867de.appspot.com",
  //messagingSenderId: "988648306770"
}

// Initialize Firebase
const app = firebase.initializeApp(options)
export const fb = firebase
export const auth = app.auth()
export const functions = app.functions()
export const db = fb.firestore()

//export default firebase
//import { fb, auth } from './firebase.js'


/////////////////////////////////////////
//firebase.initializeApp({
//  apiKey: "AIzaSyBl1WX9R7rTC8gvQGCX4xD9HXFj5xlKTMA",
//  authDomain: "songxy-867de.firebaseapp.com",
//  databaseURL: "https://songxy-867de.firebaseio.com",
//  projectId: "songxy-867de",
//  storageBucket: "songxy-867de.appspot.com",
//  messagingSenderId: "988648306770",
//  appId: "1:988648306770:web:f9c9cac1546d1540f0078b"
//});

//export const db = firebase.firestore()
//export const auth = firebase.auth();
//export const functions = firebase.functions();