import Vue from 'vue'
import VueRouter from 'vue-router'
//import store from '../store'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/*router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresGuest = to.matched.some(record => record.meta.requiresGuest)
  const anonSignUp = to.matched.some(record => record.name === 'SignUp') && store.state.currentUser && store.state.currentUser.isAnonymous

  // console.log( to )
  // console.log( from )
  // console.log(anonSignUp)

  store.dispatch('ROUTE_PENDING')

  store.dispatch('FETCH_AUTH').then(currentUser => {
    if (requiresGuest && currentUser && !anonSignUp) {
      next({
        name: 'Home'
      })
    } else if (requiresAuth && !currentUser) {
      next({
        name: 'About',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      next()
    }

    store.dispatch('ROUTE_COMPLETE')
  })

  document.title = `${to.meta.title} - Vue Firebase Anon Accounts`
})
*/
export default router
